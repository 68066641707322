.ant-btn {
    background-color: #0C2340; 
    border-color: #0C2340; 
    border-radius: 0px; 
    color: white; 
}

.ant-menu-item-selected {
    background-color: #E87722 !important; 
}

.ant-btn:hover, .ant-btn:focus {
    background-color: #E87722 !important;  
    border-color: #E87722 !important; 
    /* color: #E87722 !important;  */
}

.ant-btn-default {
    background-color: transparent !important;
    color: #0C2340 !important;
}

.ant-btn-default:hover, .ant-btn-default:focus {
    background-color: transparent !important;
    color: #E87722 !important;
    border-color: #E87722 !important;
}

.ant-btn-link {
    background-color: transparent !important;
    color: #0C2340 !important;
}

.ant-btn-link:hover, .ant-btn-link:focus {
    background-color: transparent !important;
    color: #E87722 !important;
    border-color: #E87722 !important;
}

.ant-btn-primary:disabled {
    /* background-color: #E87722 !important;  
    border-color: #E87722 !important;  */
    /* color: #E87722 !important;  */
    cursor: not-allowed !important;
    border-color: #d9d9d9 !important;
    color: rgba(12, 35, 64, 0.25) !important;
    background-color: rgba(12, 35, 64, 0.04) !important;
    box-shadow: none !important;
}

.ant-btn:disabled {
    /* background-color: #E87722 !important;  
    border-color: #E87722 !important;  */
    /* color: #E87722 !important;  */
    cursor: not-allowed !important;
    border-color: #d9d9d9 !important;
    color: rgba(12, 35, 64, 0.25) !important;
    background-color: rgba(12, 35, 64, 0.04) !important;
    box-shadow: none !important;
}


.ant-tabs-tab {
    border-radius: 0px !important;
}

.ant-tree-node-selected {
    background-color: #F1EFEF !important
}

.asset-btn-round {
    background-color: transparent !important;
    color: #E87722 !important;
    border-color: #E87722 !important;
}

.asset-btn-round:hover, .ant-btn-round:focus {
    background-color: transparent !important;
    color: #0C2340 !important;
    border-color: #0C2340 !important;
}

.notification-btn-delete {
    background-color: transparent !important;
    color: #CE0037 !important;
    border-color: #CE0037 !important;
}

.notification-btn-delete:hover, .notification-btn-delete:focus {
    background-color: #CE0037!important;
    color: white !important;
    border-color: #CE0037 !important;
}

.notification-btn-update {
    background-color:#0C2340 !important;
    color: white !important
}

.notification-btn-update:hover, .notification-btn-update:focus {
    background-color: #E87722 !important;
    color: white!important;
    border-color: #E87722 !important;
}

.no-border-radius .ant-timeline-item-head,
.no-border-radius .ant-timeline-item-tail {
    border-radius: 0 !important;
}

.orange-hover-link {
    color:#0C2340 !important;
}

.orange-hover-link:hover, .orange-hover-link:focus {
    color:#E87722 !important;
}





.pt-header {
    background-color: #0C2340 !important;
}

.custom-badge-in-progress .ant-badge-status-processing {
    background-color: #2196F3 !important; 
}


